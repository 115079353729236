import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Redesocial from "../components/Footer/RedeSociais.tsx";
import Maps from "../components/Footer/mapa.tsx";

export function Footer() {
  return (
    <Container style={{ 
      height: "20vh",
      padding: "10px", 
      justifyContent: 'center', 
      alignItems: 'center', 
      borderColor: 'black', 
      zIndex: 1000,
      marginTop: 100,
     
    }}>
      <Row>
        <Col lg={4} sm={12}>
        <Redesocial></Redesocial>
        </Col>
        <Col lg={4} sm={12} style={{textDecoration: 'none'}}>

              
            
        </Col>
        <Col lg={4} sm={12}>
        <div className= "loca" style={{alignItems:'center', textAlign: 'center'}}>
        <h5 className="font-montserrat text-xl sm:text-2xl py-1">Localização</h5>
        </div>
        <Card style={{marginTop:30, marginBottom: 30, alignItems:'center', border: 'none'}}>
          <Maps></Maps>
        </Card> 
        </Col>
      </Row>
    </Container>
  );
}