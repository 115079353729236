import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import Typed from 'typed.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import imagemPrincipal from "../../img/Home.png";
import Img from "../Biografia/Img.tsx";
import Background from '../ui/ParticlesBG/Background.tsx';
import "../Biografia/style.css";
import cv2025 from '../../curriculo/cv2025.pdf';

export default function Biografia() {
  const el = React.useRef<HTMLSpanElement>(null);
  
  const handleDownload = () => {
    const link = document.createElement("a");
    // Utilize a URL gerada pelo import
    link.href = cv2025;
    link.download = "cv2025.pdf"; // Nome do arquivo para download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    AOS.init({
      delay: 1000,
      duration: 2000,
      useClassNames: true,
    });

    const typed = new Typed(el.current, {
      strings: ['Full Stack Developer', 'Husband' ],
      typeSpeed: 50,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <Container style={{ marginTop: 70 }} className="justify-center items-center w-100 h-[90vh] gap-3 text-center p-4" data-aos="fade-up">
      <Row>
        <Col lg={6} sm={12}>
          <Card style={{ color: '#212121', border: 'none', padding: '10% 10%', textAlign: 'left', justifyContent: 'center', fontSize: '30px', height: '100%' }}>
            <h1 style={{ color: '#212121' }} className="font-montserrat font-bold" data-aos="fade-down">
              Olá, meu nome é <span style={{ color: '#F55E36' }} className="text-maincolor">Eduardo</span>.
            </h1>
            <p className="uppercase text-lg font-lato text-bolder mb-3">
              <span ref={el} />
            </p>
            <div onClick={handleDownload} className="download-btn">
              Download CV
            </div>
            <Background />
          </Card>
        </Col>

        <Col lg={6} sm={12}>
          <Card style={{ border: 'none', justifyContent: 'center', fontSize: '30px' }}>
            <Img img={imagemPrincipal} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
