import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '300px',
  height: '200px'
};

const center = {
  lat: -26.304408,
  lng: -48.848854
};

const apiKey = 'AIzaSyACccq7F1YsN0o7q8W5JiBDOFJxbyTWeBo'; // Substitua pela sua chave de API

const Maps: React.FC = () => {
  // Função para construir a URL da imagem estática e disparar o download


  return (
    <div>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
        >
          {/* Você pode adicionar outros componentes ou marcadores ao mapa aqui */}
        </GoogleMap>
      </LoadScript>
   </div>
  );
};

export default Maps;
