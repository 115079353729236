export const ProjetosLista = [
  {
    title: 'Gestão de Usuários e Controle de Acesso:',
    subtitle: 'ReactJs - Css - Docker - NodeJs - TheCatApi',
    text: 'Sistema com perfis de Administrador e Usuário Comum. O Admin gerencia outros admins, bloqueia/desbloqueia usuários e controla o CRUD de dados da API pública. Usuários comuns podem editar seus dados, excluir a conta e visualizar a API (exceto se bloqueados).',
    github: 'https://github.com/DuHansen/Cats',
    deploy: 'https://cats-vert-three.vercel.app/',
  },
  {
    title: 'Crud no firebase',
    subtitle: 'ReactApp - JS  - Css - RealTime Database - Authentication',
    text: 'Projeto criado no curso de Frontend do Senac. Trata-se de um gerenciador no qual você se cadastra, realiza login e faz postagens com título e comentário.',
    github: 'https://github.com/DuHansen/Psci',
    deploy: 'https://psci.vercel.app/',
  },
  {
    title: 'E-commerce',
    subtitle: 'JS - HTML - CSS - PayPal - Spreedly - NodeJS',
    text: 'Este projeto foi um desafio realizado pela PeakOne. Consiste em uma página de e-commerce para cadastro de perfil e definição da forma de pagamento',
    github: 'https://github.com/DuHansen/eduardo-example-campaing',
    deploy: 'https://eduardo-example-campaing.vercel.app/',
  },
  {
    title: 'Automação de Formulários e Dashboard com Integração em Tempo Real',
    subtitle: 'Python - Django - HTML - JS - CSS - Google Sheets API - Google Drive API - gspread - oauth2client',
    text: 'Projeto que coleta dados por meio de um formulário local e os organiza em um dashboard interativo, permitindo armazenamento de imagens e atualização em tempo real. Facilita a gestão e visualização das informações para uma análise mais eficiente.',
    github: 'https://github.com/DuHansen/SensorVille',
    deploy: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQmdM7SU18xnUp4zsnnouQNfmoHy91BH81OS_qZGda8YC2C9qsvrd_X8udih3WxkKld6VFmWPSXIamr/pubhtml?gid=0&single=true',
  },
  {
    title: 'Bot de Atendimento SUS',
    subtitle: 'NodeJs - wppconnect',
    text: 'Um assistente virtual que fornece informações sobre horários de atendimento, disponibilidade de vacinas, consultas e testes no SUS. Também responde dúvidas frequentes sobre atestados, agendamentos de cirurgias e filas de espera, orientando os usuários de forma rápida e acessível.',
    github: 'https://github.com/DuHansen/BotAtendimentoSus/tree/dev',
    deploy: 'https://github.com/DuHansen/BotAtendimentoSus/tree/dev',
  },
  {
    title: 'Criptografia e Descriptografia RSA com Vue.js',
    subtitle: 'Vue3 - JS',
    text: 'Projeto que utiliza o algoritmo RSA-OAEP para criptografar e descriptografar mensagens de forma segura no navegador. A aplicação gera um par de chaves (pública e privada) usando a API Web Crypto, armazena as chaves localmente e permite a criptografia de mensagens com a chave pública. A descriptografia pode ser realizada com a chave privada, garantindo a segurança dos dados. As mensagens são convertidas entre formatos binários e base64 para manipulação e armazenamento.',
    github: 'https://github.com/DuHansen/crypto',
    deploy: 'https://crypto-mauve-two.vercel.app/',
  }

]
